body {
  height: 100%;
  margin: 0;
  font-family: 'Noto Sans JP', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body > #root {
  height: 100%;
}

html {
  height: 100%;
}
